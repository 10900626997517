body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif, 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* RT-849 Scrollbar color teal */
/* Below is for Chrome and Edge */
::-webkit-scrollbar-thumb {
  background-color: #30d9c0 !important;
}

/* Below is for Firefox */
*{
  scrollbar-color: #30d9c0 #303030;
  scrollbar-width: thin;
}


